<template>
  <b-row>
    <b-col md="4">
      <b-card-normal title="Kategori Tanımlama" :showLoading="show">
        <template v-slot:body>
          <tanimlama :kategoriler="kategoriler" @showLoading="show = $event" @refresh="handlerGetData" :updateData="updateData" />
        </template>
      </b-card-normal>
    </b-col>
    <b-col md="8" order-sm="12">
      <b-card-normal title="Kategoriler" :showLoading="show">
        <template v-slot:body>
          <list :data="rows" @update="handlerUpdate($event)" @refresh="handlerGetData" />
        </template>
      </b-card-normal>
    </b-col>
  </b-row>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import Tanimlama from './component/tanimlama.vue';
import List from './component/list.vue';
import { ref } from '@vue/composition-api';
import store from '@/store';
export default {
  components: {
    BCardNormal,
    Tanimlama,
    List,
  },

  setup() {
    const expo = {};
    expo.show = ref(false);
    expo.kategoriler = ref([]);
    expo.rows = ref([]);
    expo.updateData = ref({});

    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.handlerGetData = async () => {
      expo.show.value = true;
      expo.rows.value = [];
      expo.kategoriler.value = [];
      await store.dispatch('kategoriListele');
      const kat = await store.getters.getKategoriler;

      kat.forEach((el) => {
        if (el.kategori_k_no == null || el.kategori_k_no == '')
          expo.kategoriler.value.push({ kategori_k_no: el.k_no, baslik: el.icerik[expo.defaultDil.value].baslik });
      });
      await kat.forEach((el, i) => {
        if (el.kategori_k_no == null || el.kategori_k_no == '') expo.rows.value.push({ ...el, children: [] });
      });

      await kat.forEach((el, i) => {
        if (el.kategori_k_no != null) {
          let index = expo.kategoriler.value.findIndex((x) => x.kategori_k_no == el.kategori_k_no);
          if (index != -1) {
            expo.rows.value[index].children.push(el);
          }
        }
      });

      expo.updateData.value = {};

      expo.show.value = false;
    };

    expo.handlerGetData();

    expo.handlerUpdate = (event) => {
      expo.updateData.value = event;
    };

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
